import { SvcApiConstants, WarrantyExtensionConstants } from '../../../helpers/Constants';
import {
    deleteJanrainCaptureProfileDataFromLocalStorage,
    getJanrainCaptureTokenFromLocalStorage
} from '../../../../components/Janrain/helpers/localstorage.helpers';
import {getJanrainCaptureProfileDataFromLocalStorage} from '../../../../components/Janrain/helpers/localstorage.helpers';
import {redirectTo} from '../../../../components/Janrain/helpers/redirect.helpers';
import { customJsonstringify, customSuperJsonParse, customSuperJsonStringify } from '../../../helpers/Utils';

export function generateHeader(token) {
    let header = new Headers();
    header.append(SvcApiConstants.contentType, 'application/x-www-form-urlencoded');
    header.append(SvcApiConstants.ciamInstanceId, process.env.SVC_API_CIAM_INSTANCE_ID);

    const Authorization = 'Bearer ' + token;

    header.append('Authorization', Authorization);
    header.append('Cache-Control', 'no-cache');

    return header;
}

export const postReplaceEntity = (object, newJanrainProduct, onSuccess = function(){}, onFailure = function(){}, registerData = {}) => {
    const orderId = registerData?.requestId
    // set order id has productId for the new product -
    // with the orderProductId value to find the traitproduct while delate time
    newJanrainProduct.productId = `${orderId}`;
    object.traitProductType.push(newJanrainProduct);

    const token = getJanrainCaptureTokenFromLocalStorage();

    const header = generateHeader(token);
    var params = {
        attributes: customJsonstringify(object),
        type_name: process.env.SVC_API_TYPE_NAME
    };

    const searchParams = new URLSearchParams();
    for (const prop in params) {
        searchParams.set(prop, params[prop]);
    }

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: searchParams
    };
    fetch(process.env.SVC_API_URL_REPLACE, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.stat) {
                if(data.stat === 'ok') {
                    onSuccess(orderId, newJanrainProduct, registerData);
                } else if (data.stat === 'error') {
                    console.error('API entity.replace error:',data.error_description);
                    onFailure(orderId);
                    if(data.error_description === 'unknown access token') {
                        logout();
                    }
                } else {
                    onFailure(orderId);
                    if(data?.error_description) {
                        console.error('API entity.replace error:',data.error_description);
                    }
                }
            }
        });

}

export const postReplaceEntityUpdate = (object, onSuccess = function(){}, onFailure = function(){}) => {
    const token = getJanrainCaptureTokenFromLocalStorage();

    const header = generateHeader(token);
    var params = {
        attributes: customJsonstringify(object),
        type_name: process.env.SVC_API_TYPE_NAME
    };

    const searchParams = new URLSearchParams();
    for (const prop in params) {
        searchParams.set(prop, params[prop]);
    }

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: searchParams
    };

    fetch(process.env.SVC_API_URL_REPLACE, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.stat) {
                if(data.stat === 'ok') {
                    refreshEntity(onSuccess);
                } else if (data.stat === 'error') {
                    console.error('API entity.replace error:',data.error_description);
                    onFailure();
                    if(data.error_description === 'unknown access token') {
                        logout();
                    }
                } else {
                    onFailure();
                    if(data?.error_description) {
                        console.error('API entity.replace error:',data.error_description);
                    }
                }
            }
        });

}

export const postReplaceGoals = (object, onSuccess = function(){}, onFailure = function(){}) => {
    const token = getJanrainCaptureTokenFromLocalStorage();

    const header = generateHeader(token);
    var params = {
        attributes: customJsonstringify(object),
        type_name: process.env.SVC_API_TYPE_NAME
    };

    const searchParams = new URLSearchParams();
    for (const prop in params) {
        searchParams.set(prop, params[prop]);
    }

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: searchParams
    };

    fetch(process.env.SVC_API_URL_REPLACE, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.stat) {
                if(data.stat === 'ok') {
                    console.info('replaceEntity');
                    onSuccess();
                } else if (data.stat === 'error') {
                    console.error('API entity.replace error:',data.error_description);
                    onFailure();
                    if(data.error_description === SvcApiConstants.unknownAccessToken) {
                        logout();
                    }
                } else {
                    onFailure();
                    console.error('API entity.replace error:',data?.error_description);
                }
            }
        });
};

export const postReplaceBenefitPreferences = (object, onSuccess = function(){}, onFailure = function(){}) => {
    const token = getJanrainCaptureTokenFromLocalStorage();

    const header = generateHeader(token);
    var params = {
        attributes: customJsonstringify(object),
        type_name: process.env.SVC_API_TYPE_NAME
    };

    const searchParams = new URLSearchParams();
    for (const prop in params) {
        searchParams.set(prop, params[prop]);
    }

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: searchParams
    };

    fetch(process.env.SVC_API_URL_REPLACE, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.stat) {
                if(data.stat === 'ok') {
                    console.info('replaceEntity');
                    onSuccess();
                } else if (data.stat === 'error') {
                    console.error('API entity.replace error:',data.error_description);
                    onFailure();
                    if(data.error_description === 'unknown access token') {
                        logout();
                    }
                } else {
                    onFailure();
                    console.error('API entity.replace error:',data.error_description);
                }
            }
            refreshEntity();
        });

}

// Refresh janrainCaptureProfileData localStorage data,
// we need to do this operation because the svc api do not refresh the state of the widget
export const refreshEntity = (onSuccess = function(){}, onFailure = function(){}) => {
    const token = getJanrainCaptureTokenFromLocalStorage();

    const header = generateHeader(token);

    const requestOptions = {
        method: 'POST',
        headers: header
    };
    let userData = {};
    fetch(process.env.SVC_API_URL_ENTITY, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.stat) {
                if(data.stat === 'error') {
                    console.error('API entity error:',data.error_description);
                    if(data.error_description === 'unknown access token') {
                        logout();
                    } else {
                        onFailure();
                    }
                } else {
                    userData = data.result;
                    localStorage.setItem('janrainCaptureProfileData', customSuperJsonStringify(userData));
                    onSuccess();
                }
            }
        }).catch(error => {
            console.error(error);
            onFailure();
        });

}

// The is an issue an janrain side where the consumerID creation is delayed by around 15 sec.
// we have to wait for the consumerID before we can allow the user to register a product.
export const waitConsumerID = (successRedirectPath) => {
    let janrainProfile = customSuperJsonParse(getJanrainCaptureProfileDataFromLocalStorage());
    let consumerID = janrainProfile.consumerID;
    let attempThreshold = parseInt(process.env.WARRANTY_API_CONSUMER_ID_ATTEMP_TRESHOLD);
    let attempNumber = 0;

    // declare recursive function
    function recursiveUserEntityRefresh(consumerID, attempNumber, attempThreshold) {
        refreshEntity();
        janrainProfile = customSuperJsonParse(getJanrainCaptureProfileDataFromLocalStorage());
        if (janrainProfile.consumerID) {
            consumerID = janrainProfile.consumerID;
        }
        attempNumber = attempNumber + 1;
        // time out, we wait
        if (!consumerID && attempNumber < attempThreshold) {
            setTimeout(function() { recursiveUserEntityRefresh(consumerID, attempNumber, attempThreshold) },
                parseInt(process.env.WARRANTY_API_CONSUMER_ID_RECURSIVE_TIMEOUT_TIME));
        } else {
            if (consumerID) {
                redirectTo(successRedirectPath);
            } else {
                // invalidate session
                if (window?.janrain?.capture?.ui?.endCaptureSession) {
                    window.janrain.capture.ui.endCaptureSession();
                } else {
                    deleteJanrainCaptureProfileDataFromLocalStorage();
                }
                redirectTo(process.env.JANRAIN_LOGIN_PATH);
            }
        }
    }

    // call recursive function if consumerID is empty
    if (!consumerID) {
        recursiveUserEntityRefresh(consumerID, attempNumber, attempThreshold);
    } else {
        redirectTo(successRedirectPath);
    }
}

export const getPostReplaceEntityObject = (janrainProfileData) => {
    let postObject = {}

    // plural
    let traitProductTypeValue = removePluralListId(janrainProfileData?.traitProductType);
    postObject['traitProductType'] = traitProductTypeValue;

    let traitBenefitPreferencesValue = removePluralListId(janrainProfileData?.traitBenefitPreferences);
    postObject['traitBenefitPreferences'] = traitBenefitPreferencesValue;

    let optInsValue = removePluralListId(janrainProfileData?.optIns);
    postObject['optIns'] = optInsValue;

    // string or object
    postObject['traitBrushHeadReminderOptInFlag'] = janrainProfileData?.traitBrushHeadReminderOptInFlag;
    postObject['traitBrushheadReminderDate'] = janrainProfileData?.traitBrushheadReminderDate;
    postObject['primaryProduct'] = janrainProfileData?.primaryProduct;
    postObject['traitPrimaryBrush'] = janrainProfileData?.traitPrimaryBrush;

    return postObject;
}

export const removePluralListId = (pluralList) => {
    if(pluralList) {
        pluralList.forEach(object => {
            delete object.id;
        });
    }
    return pluralList;
}

export const logout = () => {
    if (window?.janrain?.capture?.ui?.endCaptureSession) {
        window.janrain.capture.ui.endCaptureSession();
        redirectTo(process.env.JANRAIN_LOGIN_PATH);
    } else {
        deleteJanrainCaptureProfileDataFromLocalStorage();
        redirectTo(process.env.JANRAIN_LOGIN_PATH);
    }
}

export const sendEmailVerification = (email, onSuccess = function(){}, onFailure = function(){}) => {
    let header = new Headers();
    header.append(SvcApiConstants.contentType, 'application/x-www-form-urlencoded');
    header.append(SvcApiConstants.ciamInstanceId, process.env.SVC_API_CIAM_INSTANCE_ID);

    var params = {
        flow: process.env.JANRAIN_CAPTURE_FLOW_NAME,
        flow_version: process.env.JANRAIN_CAPTURE_FLOW_VERSION,
        locale: process.env.JANRAIN_CAPTURE_LOCALE,
        form: SvcApiConstants.resendVerificationForm,
        signInEmailAddress: email,
        client_id: process.env.JANRAIN_CAPTURE_CIENT_ID,
        // this redirect is not used in the implementation we are doing.
        // It fails if empty so we are letting the default value from documentation
        redirect_uri: 'http://localhost'
    };

    const searchParams = new URLSearchParams();
    for (const prop in params) {
        searchParams.set(prop, params[prop]);
    }

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: searchParams
    };

    fetch(process.env.SVC_API_URL_SEND_VERIFICATION_EMAIL, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data?.stat === 'ok') {
                onSuccess();
            } else {
                console.error('Double optIn verification email not sent:', data);
                onFailure();
            }
        }).catch(error => {
            console.log('Double optIn verification email not sent:', error);
            onFailure();
        });

}

export const setEntityOptInStatus = (status, token, onSuccess = function(){}, onFailure = function(){}) => {

    // Step 1 get entity with token
    const headerEntity = generateHeader(token);

    const requestOptionsEntity = {
        method: 'POST',
        headers: headerEntity
    };
    let userData = {};
    fetch(process.env.SVC_API_URL_ENTITY, requestOptionsEntity)
        .then(response => response.json())
        .then(data => {
            if (data.stat) {
                if (data.stat === 'error') {
                    console.error('API entity error:', data.error_description);
                    if (data.error_description === 'unknown access token') {
                        logout();
                    }
                } else {
                    userData = data.result;
                    localStorage.setItem('janrainCaptureProfileData', customSuperJsonStringify(userData));
                    // Step 2: post replace entity call to update optStatus to true
                    let postObject = getPostReplaceEntityObject(userData);

                    // make sure we have one optins
                    if (postObject['optIns'][0]) {
                        postObject['optIns'][0].optStatus = true;                        
                        if(userData && userData.emailVerified){
                            postObject['optIns'][0].choiceDate = userData.emailVerified;
                        }
                    }
                    postEntityOptStatus(postObject, token, onSuccess, onFailure);
                }
            }
        });

}

// We have 3 post replace entity method which are very similar except their callback
// Might be optimizable to have only one method
export const postEntityOptStatus = (postObject, token, onSuccess = function(){}, onFailure = function(){}) => {
    const header = generateHeader(token);
    var params = {
        attributes: customJsonstringify(postObject),
        type_name: process.env.SVC_API_TYPE_NAME
    };

    const searchParams = new URLSearchParams();
    for (const prop in params) {
        searchParams.set(prop, params[prop]);
    }

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: searchParams
    };

    fetch(process.env.SVC_API_URL_REPLACE, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.stat) {
                if(data.stat === 'ok') {
                    onSuccess();
                    refreshEntity();
                } else if (data.stat === 'error') {
                    console.error('API entity.replace error:',data.error_description);
                    onFailure();
                    if(data.error_description === 'unknown access token') {
                        logout();
                    }
                } else {
                    onFailure();
                    if(data?.error_description) {
                        console.error('API entity.replace error:',data.error_description);
                    }
                }
            }
        });

}

